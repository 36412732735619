<template>
    <div>
        <!-- 第一區 -->
        <div class="bg-sukudai-green">
            <b-container class="bv-example-row">
                <!-- 關於我們 -->
                <b-row class="pt-4 py-3 align-items-center">
                    <!-- 左側 -->
                    <b-col cols="12" lg="6">
                        <b-row class="pb-3">
                            <b-col cols="12">
                                <b-row class="p-2 justify-content-center ">
                                    <b-col cols="2" class="w-100"
                                           style="background: linear-gradient(transparent 54%, #fff 54%, #fff 56%, transparent 56%)"/>
                                    <b-col cols="6">
                                        <h2 class="text-white col-12 text-center font-weight-bolder">關於我們</h2>
                                    </b-col>
                                    <b-col cols="2" class="w-100"
                                           style="background: linear-gradient(transparent 54%, #fff 54%, #fff 56%, transparent 56%)"/>
                                </b-row>
                            </b-col>

                            <b-col cols="12" class="p-3">
                                <div class="bg-white rounded p-3">
                                    <div style="border-left:1px solid #000" class="d-flex flex-column pl-3">
                                        <span class="px-2 py-1 mb-2 bg-sukudai-info font-weight-bold w-fit-content font-lg">
                                            別人不行的交給我們!!
                                        </span>
                                        <span>
                                            速可貸本著誠信、專業、服務的理念提供多項金融服務，並提供諮詢、輔導及規劃。<br/>
                                            用心感受客戶的需要，是我們的宗旨；在面對生活上各種困境時的無奈、絕望與不知所措的心情，速可貸始終與客戶一同面對，在最重要的時刻，給予客戶最大的協助。<br/>
                                            有別於傳統多維度的徵信模型與申貸管道，我們擁有更好的創新效率；採用最高層級的客戶個資保密措施，絕對保障客戶隱私。
                                        </span>
                                    </div>
                                </div>
                            </b-col>
                        </b-row>
                    </b-col>

                    <!-- 第二區 右側 -->
                    <b-col cols="12" lg="6">
                        <div class="py-3">
                            <ImageCarousel carousel-name="AboutUs"/>
                        </div>
                    </b-col>
                </b-row>

                <b-row class="pb-3 align-items-center">
                    <!-- 第一區 左側 -->
                    <b-col cols="12" lg="6">
                        <div class="py-3">
                            <ImageCarousel carousel-name="AboutUs"/>
                        </div>
                    </b-col>

                    <!-- 第二區 右側 -->
                    <b-col cols="12" lg="6">
                        <b-row class="py-3">
                            <b-col cols="12">
                                <b-row class="p-2 justify-content-center ">
                                    <b-col cols="2" class="w-100"
                                           style="background: linear-gradient(transparent 54%, #fff 54%, #fff 56%, transparent 56%)"/>
                                    <b-col cols="6">
                                        <h2 class="text-white col-12 text-center font-weight-bolder">專業團隊</h2>
                                    </b-col>
                                    <b-col cols="2" class="w-100"
                                           style="background: linear-gradient(transparent 54%, #fff 54%, #fff 56%, transparent 56%)"/>
                                </b-row>
                            </b-col>
                            <b-col cols="12" class="p-3">
                                <div class="bg-white rounded p-3">
                                    <div style="border-left:1px solid #000" class="d-flex flex-column pl-3">
                                        <span class="px-2 py-1 mb-2 bg-sukudai-info font-weight-bold w-fit-content font-lg">
                                            專業團隊
                                        </span>
                                        <span>
                                            我們是一群擁有金融與法務專業經驗的理財團隊，不管是汽機車借貸、房貸/二胎、信貸、代書、企業貸款、商品貸款、債務整合等，都可以為您個人規劃專案。<br/>
                                            速可貸秉持深度顧問式整合服務，根據客戶的資金需求，從需求診斷、專屬顧問、客製規劃及後續需求資源整合，協助客戶迅速取得資金。<br/>
                                            專業誠信、永續經營的理念以陪我們走過10個年頭，已成功幫助上萬位客戶解決資金問題。
                                        </span>
                                    </div>
                                </div>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-container>

            <!-- 諮詢按鈕 -->
            <div class="py-2 row justify-content-center">
                <b-button variant="warning" size="lg"
                          class="text-white font-weight-bold mr-5 border-0 rounded-0 px-5"
                          :to="{ path: 'FormConsultation' }">
                    表單諮詢
                </b-button>
                <b-button size="lg"
                          class="text-white font-weight-bold border-0 rounded-0 px-5 bg-sukudai-title-info"
                          @click="$bvModal.show(modalId)">
                    立即諮詢
                </b-button>
            </div>

            <b-col cols="12" class="my-3">
                <WeHelp/>
            </b-col>
        </div>

        <!-- 成功核貸用戶心得 -->
        <comment/>

        <b-modal :id="modalId" hide-footer>
            <quick-submit/>
        </b-modal>
    </div>
</template>

<script>
import comment from "./comment";
import WeHelp from "../components/WeHelp";
import ImageCarousel from "./ImageCarousel";
import QuickSubmit from "@/components/quickSubmit";

export default {
    components: {
        QuickSubmit,
        comment,
        WeHelp,
        ImageCarousel
    },

    data() {
        return {
            modalId: 'contact-now-modal'
        }
    },

    methods: {
        onSlideStart() {
            this.sliding = true
        },
        onSlideEnd() {
            this.sliding = false
        },
    },
}
</script>